import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import containerStyle from './style';
const apiBaseUrl = process.env.REACT_APP_API_URL;

const locations = [
  {
    id: 1,
    fakeCoordinates: '37.786298, -122.411414',
    fakeLink: 'https://www.google.com/maps/place/37%C2%B047\'10.7%22N+122%C2%B024\'41.1%22W/@37.786322,-122.4113971,20.75z/data=!4m4!3m3!8m2!3d37.786298!4d-122.411414',
    targetLatitude: 37.786298,
    targetLongitude: -122.411414,
    // targetLatitude: 39.734663,//currentLocation in chico
    // targetLongitude:  -121.856138,
    textToShow: 'Great, let\'s begin.',
  },
  {
    id: 2,
    fakeCoordinates: '37.801937, -122.420200',
    fakeLink: 'https://www.google.com/maps/place/Lombard+St,+San+Francisco,+CA/@37.8020255,-122.4205116,18.48z/data=!4m6!3m5!1s0x808580ddab81ad97:0xc42f0b97ddc2b2ac!8m2!3d37.8020146!4d-122.4195551',
    targetLatitude: 37.801937,
    targetLongitude: -122.420200,
    textToShow: 'Great, let\'s begin.',
  },
  {
    id: 3,
    fakeCoordinates: '37.807633, -122.410643',
    fakeLink: 'https://www.google.com/maps/place/2350+Stockton+St,+San+Francisco,+CA+94133/@37.8078299,-122.4105895,111m/data=!3m1!1e3!4m6!3m5!1s0x808580fb70315397:0x37d3b12098952c4c!8m2!3d37.8075665!4d-122.4105137',
    targetLatitude: 37.807633,
    targetLongitude: -122.410643,
    textToShow: 'Great, let\'s begin.',
  },
  {
    id: 4,
    fakeCoordinates: '37.806415, -122.422231',
    fakeLink: 'https://www.google.com/maps/search/37.806415,+-122.422231?sa=X&ved=1t:242&ictx=111',
    targetLatitude: 37.806415,
    targetLongitude: -122.422231,
    textToShow: 'Great, let\'s begin.',
  },
  {
    id: 5,
    fakeCoordinates: '37.801724, -122.448125',
    fakeLink: 'https://www.google.com/maps/search/37.801724,+-122.448125?sa=X&ved=1t:242&ictx=111',
    targetLatitude: 37.801724,
    targetLongitude: -122.448125,
    textToShow: 'Great, let\'s begin.',
  },
  {
    id: 6,
    fakeCoordinates: '37.803645, -122.458728',
    fakeLink: 'https://www.google.com/maps/search/37.803645,+-122.458728?sa=X&ved=1t:242&ictx=111',
    targetLatitude: 37.803645,
    targetLongitude: -122.458728,
    textToShow: 'Great, let\'s begin.',
  },
];



function Verify({ locationNumber, token }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [timer, setTimer] = useState(15);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);

  const showContinueButton = location.state?.showContinueButton ?? false;

  // Rename to avoid conflict
  const currentLocation = locations.find(loc => loc.id === locationNumber);
  useEffect(() => {
    let interval = null;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer(prev => prev - 1);
      }, 1000);
    } else {
      setIsButtonDisabled(false);
    }
    return () => clearInterval(interval);
  }, [timer]);

  useEffect(() => {
    // Get user's current location
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        error => {
          console.error('Error getting location:', error);
          alert('Unable to retrieve your location');
        }
      );
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  }, []);

  const handleVerify = () => {
    if (latitude === null || longitude === null) {
      alert('Unable to retrieve your current location.');
      return;
    }

    axios
      .post(
        `${apiBaseUrl}api/verify-location`,
        {
          latitude,
          longitude,
          targetLatitude: currentLocation.targetLatitude,
          targetLongitude: currentLocation.targetLongitude,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(response => {
        if (response.data.verified) {
          navigate('/hint');
        } else {
          // Display user's current coordinates
          alert(
            `You are not at the correct location.\nYour current location is:\nLatitude: ${latitude.toFixed(6)}, Longitude: ${longitude.toFixed(6)}\nPlease get closer.`
          );
        }
      })
      .catch(error => {
        console.error('Verification error:', error);
        alert('Error verifying location.');
      });
  };

  const handleContinue = () => {
    navigate('/hint');
  };

  return (
    <div className="verify-page" style={containerStyle}>
      <div className="container">
        <h1 className="heading">
          <img src={require('../assets/logo.jpg')} alt="Logo" />
          Faller Up
        </h1>
        <div className="page-content">
          <p>Location {locationNumber} of 6</p>
          <p>{currentLocation.textToShow}</p>
          <p>Reach these coordinates and get more info:</p>
          <h2>{currentLocation.fakeCoordinates}</h2>

          <a href={currentLocation.fakeLink} target="_blank" rel="noopener noreferrer" style={{ color: '#fff' }}>
            Open in Google Maps
          </a>
          <p style={{ color: '#fff', marginBottom: '50px' }}> </p>

          {/* {latitude && longitude && (
            <>
              <p>Your current location:</p>
              <p>Latitude: {latitude.toFixed(6)}</p>
              <p>Longitude: {longitude.toFixed(6)}</p>
            </>
          )} */}

          <p style={{ color: '#fff', marginBottom: '50px' }}> </p>
          <br />
          <button onClick={handleVerify} disabled={isButtonDisabled}>
            {isButtonDisabled ? `Verify (${timer}s)` : 'Verify'}
          </button>
          {/* Conditionally render the "Continue" button */}
          {showContinueButton && (
            <button onClick={handleContinue} style={{ marginLeft: '10px' }}>
              Continue
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Verify;