import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import containerStyle from './style';

function StartPage({ locationNumber, handleLogout }) {
  const navigate = useNavigate();
  const [showContinueButton, setShowContinueButton] = useState(false);

  const handleStart = () => {
    navigate('/verify', { state: { showContinueButton } });
  };

  const handleToggleChange = (e) => {
    setShowContinueButton(e.target.checked);
  };

  return (
    <div className="start-page" style={containerStyle}>
      <div className="container">
        <button onClick={handleLogout} style={{ float: 'right' }}>
          Logout
        </button>
        <h1 className="heading">
          <img src={require('../assets/logo.jpg')} alt="Logo" />
          Faller
        </h1>
        <div className="page-content">
          {locationNumber === 1 ? (
            <p>Are you ready for the first location?</p>
          ) : (
            <p>Are you ready for the next location?</p>
          )}
          <div style={{ marginBottom: '20px' }}>
            <label>
              <input
                type="checkbox"
                checked={showContinueButton}
                onChange={handleToggleChange}
              />{' '}
              Show "Continue" button on the next page
            </label>
          </div>
          <button onClick={handleStart}>Start</button>
        </div>
      </div>
    </div>
  );
}

export default StartPage;
