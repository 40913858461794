import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
const apiBaseUrl = process.env.REACT_APP_API_URL;

function Signup({ handleSignup }) {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = e => {
    e.preventDefault();

    axios
      .post(`${apiBaseUrl}api/register`, { email: email, password: password })
      .then(response => {
        const token = response.data.token;
        handleSignup(email, token);
        navigate('/');
      })
      .catch(error => {
        console.error('Signup error:', error);
        alert('Error registering user');
      });
  };

  return (
    console.log(apiBaseUrl),
    <div className="signup-page"> 
      <div className="container">
        <h2 style={{ textAlign: 'center' }}>Sign up for Faller Up</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Email:</label>
            <input
              type="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Password:</label>
            <input
              type="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit">Sign up</button>
        </form>
        <p>
          Already have an account?{' '}
          <Link to="/login">Login here</Link>
        </p>
      </div>
    </div>
  );
}

export default Signup;
