import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
const apiBaseUrl = process.env.REACT_APP_API_URL;

function Login({ handleLogin }) {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = e => {
    e.preventDefault();

    axios
    .post(`${apiBaseUrl}api/login`, { email: email, password: password })
    .then(response => {
        const token = response.data.token;
        handleLogin(email, token);
        navigate('/');
      })
      .catch(error => {
        console.error('Login error:', error);
        alert('Invalid email or password');
      });
  };

  return (
    <div className="login-page">
      <div className="container">
        <h2 style={{ textAlign: 'center' }}>Login to Faller Up</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Email:</label>
            <input
              type="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Password:</label>
            <input
              type="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit">Login</button>
        </form>
        <p>
          Don't have an account?{' '}
          <Link to="/signup">Sign up here</Link>
        </p>
      </div>
    </div>
  );
}

export default Login;
