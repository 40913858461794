import { useNavigate } from 'react-router-dom';
import containerStyle from './style.js';
import axios from 'axios';
const apiBaseUrl = process.env.REACT_APP_API_URL;

const hints = [
  {
    id: 1,
    hint: 'Find the drink that will make our sunset sparkle; hidden in the heart of the city’s hustle.'
  },
  {
    id: 2,
    hint: 'We re headed to a street that twists and turns, where flowers bloom, and wheels dance around.'
  },
  {
    id: 3,
    hint: 'A place by the bay, where fresh bread fills the air and flavors come alive in a bowl or bun.'
  },
  {
    id: 4,
    hint: 'A treasure of gold-wrapped delights, where cocoa fills the air and sweetness is found in every bite.'
  },
  {
    id: 5,
    hint: 'Grand domes and graceful columns stand by a tranquil lake, a place where art meets nature in a beautiful embrace.'
  },
  {
    id: 6,
    hint: 'A place where land meets sea, and the bridge stands tall, waiting for us to watch the sun’s fall.'
  }
];

function Hint({ locationNumber, correctAnswers, incorrectAnswers, setCorrectAnswers, setIncorrectAnswers, token }) {
  const navigate = useNavigate();
  const locationHint = hints.find(h => h.id === locationNumber);

  const updateProgress = (newProgress) => {
    axios
      .post(`${apiBaseUrl}api/progress`, newProgress, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(response => {
        console.log('Progress updated');
      })
      .catch(error => {
        console.error('Error updating progress:', error);
      });
  };

  // Handle button clicks for Yes/No
  const handleAnswer = (answer) => {
    let newCorrectAnswers = [...correctAnswers];
    let newIncorrectAnswers = [...incorrectAnswers];

    if (answer === 'yes') {
      // Check if locationNumber is already in correctAnswers
      if (!newCorrectAnswers.includes(locationNumber)) {
        newCorrectAnswers.push(locationNumber);
      }
      setCorrectAnswers(newCorrectAnswers);
    } else {
      // Check if locationNumber is already in incorrectAnswers
      if (!newIncorrectAnswers.includes(locationNumber)) {
        newIncorrectAnswers.push(locationNumber);
      }
      setIncorrectAnswers(newIncorrectAnswers);
    }

    // Update progress in backend
    updateProgress({
      locationNumber,
      correctAnswers: newCorrectAnswers,
      incorrectAnswers: newIncorrectAnswers,
    });

    navigate('/answer');
  };

  return (
    <div className="hint-page" style={containerStyle}>
      <div className="container">
        <h1 className="heading">
          <img src={require('../assets/logo.jpg')} alt="Logo" />
          Faller Up
        </h1>
        <div className="page-content">
          <p>Location {locationNumber}</p>  
          {locationNumber === 1 && (<p>Hey, this is a hint! Find a place and go in there—this is part of a treasure hunt.</p>)}

          <h2>Hint:</h2>
          <p>{locationHint.hint}</p>
          <p>Were you able to solve it?</p>
          <div>
            <button onClick={() => handleAnswer('yes')}>Yes</button>
            <button onClick={() => handleAnswer('no')} style={{ marginLeft: '10px' }}>No</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hint;
