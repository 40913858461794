import React from 'react';
import { useNavigate } from 'react-router-dom';
import containerStyle from './style';
import axios from 'axios';

const apiBaseUrl = process.env.REACT_APP_API_URL;

const answers = [
  {
    id: 1,
    name: 'Go get some wine from Napa Valley Winery Exchange',
    actualCoordinates: '37°47\'10.9"N, 122°24\'41.2"W',
    actualLink: 'https://www.google.com/maps/place/Napa+Valley+Winery+Exchange/@37.7836637,-122.4159098,4882m/data=!3m1!1e3!4m6!3m5!1s0x8085808fcb26cbd9:0x9e2208741f24b4e0!8m2!3d37.7862959!4d-122.4115414!16s%2Fg%2F1tdrflcd?entry=ttu&g_ep=EgoyMDI0MTAwMi4xIKXMDSoASAFQAw%3D%3D',
  },
  {
    id: 2,
    name: 'Enjoy the experience at Lombard Street',
    actualCoordinates: '37.8021° N, 122.4187° W',
    actualLink: 'https://www.google.com/maps/place/Lombard+St,+San+Francisco,+CA/@37.8021185,-122.4193893,411m/data=!3m1!1e3!4m6!3m5!1s0x808580ddab81ad97:0xc42f0b97ddc2b2ac!8m2!3d37.8020146!4d-122.4195551',
  },
  {
    id: 3,
    name: 'Buy some sandwich from Boudin Bakery Café (Pier 39)',
    actualCoordinates: '37.8087° N, 122.4098° W',
    actualLink: 'https://www.google.com/maps/place/Boudin+Bakery+Cafe/@37.8088086,-122.409821,15z/data=!4m2!3m1!1s0x0:0x4093a3b1d30128d4?sa=X&ved=1t:2428&ictx=111',
  },
  {
    id: 4,
    name: 'Become a Sweet Tooth at Ghirardelli Square',
    actualCoordinates: '37.8058° N, 122.4229° W',
    actualLink: 'https://www.google.com/maps/place/Ghirardelli+Chocolate+Experience/@37.8058669,-122.4236168,865m/data=!3m2!1e3!5s0x808580e0941b4d6b:0x7edf0a3aeca12e60!4m14!1m7!3m6!1s0x808581b0eecf98a7:0xa98007f98754ca45!2sGhirardelli+Square!8m2!3d37.8055225!4d-122.422673!16s%2Fg%2F11vzgy_ttf!3m5!1s0x808580e0c17679bd:0xd77cc13a87672c8d!8m2!3d37.8059485!4d-122.4229383!16zL20vMDU4aDJs?entry=ttu&g_ep=EgoyMDI0MTAwMi4xIKXMDSoASAFQAw%3D%3D',
  },
  {
    id: 5,
    name: 'Click some pics at Palace of Fine Arts',
    actualCoordinates: '37.8024° N, 122.4483° W',
    actualLink: 'https://www.google.com/maps/place/Palace+of+Fine+Arts/@37.8029308,-122.4484231,15z/data=!4m2!3m1!1s0x0:0xe960db8a581aacf5?sa=X&ved=1t:2428&ictx=111',
  },
  {
    id: 6,
    name: 'Have food and enjoy at Crissy Field',
    actualCoordinates: '37.8036° N, 122.4640° W',
    actualLink: 'https://www.google.com/maps/place/Crissy+Field/@37.8040257,-122.4650165,865m/data=!3m2!1e3!4b1!4m6!3m5!1s0x808586dc8525b355:0x5b15912201aa3600!8m2!3d37.8040257!4d-122.4650165!16zL20vMDYxdGp4?entry=ttu&g_ep=EgoyMDI0MTAwMi4xIKXMDSoASAFQAw%3D%3D',
  },
];

function Answer({ locationNumber, setLocationNumber, correctAnswers, incorrectAnswers, token }) {
  const navigate = useNavigate();
  const locationAnswer = answers.find(a => a.id === locationNumber);

  const updateProgress = (newProgress) => {
    axios
      .post(`${apiBaseUrl}api/progress`, newProgress, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(response => {
        console.log('Progress updated');
      })
      .catch(error => {
        console.error('Error updating progress:', error);
      });
  };

  const handleNext = () => {
    if (locationNumber >= 6) {
      // Reset progress
      updateProgress({
        locationNumber: 1,
        correctAnswers: [],
        incorrectAnswers: [],
      });
      navigate('/final');
    } else {
      // Continue to next location
      const newLocationNumber = locationNumber + 1;
      setLocationNumber(newLocationNumber);
  
      updateProgress({
        locationNumber: newLocationNumber,
        correctAnswers,
        incorrectAnswers,
      });
  
      navigate('/');
    }
  };
  

  return (
    <div className="answer-page" style={containerStyle}>
      <div className="container">
        <h1 className="heading">
          <img src={require('../assets/logo.jpg')} alt="Logo" />
          Faller Up
        </h1>
        <div className="page-content">
          <p>The answer to the hint is:</p>
          <h2>{locationAnswer.name}</h2>
          <p>Coordinates: {locationAnswer.actualCoordinates}</p>
          <a href={locationAnswer.actualLink} target="_blank" rel="noopener noreferrer" style={{ color: '#fff' }}>
            View on Google Maps
          </a>
          <br />
          <p style={{ color: '#fff', marginBottom: '10px' }}> </p>
          <button onClick={handleNext}>Proceed</button>
        </div>
      </div>
    </div>
  );
}

export default Answer;