const containerStyle = {
    backgroundColor: 'rgba(128, 128, 128, 0.5)', // Grey background with some opacity
    borderRadius: '15px', // Curved corners
    padding: '20px', // Padding around content
    textAlign: 'center', // Center-align text
    maxWidth: '500px', // Adjust the width as needed
    margin: '100px auto', // Center the block on the page
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 1)', // Soft shadow for better visibility
  };
  
  export default containerStyle;
  