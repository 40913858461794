import React from 'react';
import { useNavigate } from 'react-router-dom';
import containerStyle from './style';

function Final({ correctAnswers, incorrectAnswers, handleLogout }) {
  const navigate = useNavigate();

  const handleFinish = () => {
    handleLogout();
    navigate('/');
  };

  return (
    <div className="final-page" style={containerStyle}>
      <div className="container">
        <h1 className="heading">
          <img src={require('../assets/logo.jpg')} alt="Logo" />
          Faller Up
        </h1>
        <div className="page-content">
          <p>You have completed all the locations.</p>
          <p>You solved {correctAnswers.length} out of 6 hints correctly.</p>
          {correctAnswers.length > 0 && (
            <p>You solved hints at locations: {correctAnswers.join(', ')}.</p>
          )}
          {incorrectAnswers.length > 0 && (
            <p>You could not solve hints at locations: {incorrectAnswers.join(', ')}.</p>
          )}
          <button onClick={handleFinish}>Finish</button>
        </div>
      </div>
    </div>
  );
}

export default Final;
