import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import axios from 'axios';
import Login from './components/Login';
import Signup from './components/Signup';
import Home from './components/Home';
import StartPage from './components/StartPage';
import Verify from './components/Verify';
import Hint from './components/Hint';
import Answer from './components/Answer';
import Final from './components/Final';
const apiBaseUrl = process.env.REACT_APP_API_URL;

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [locationNumber, setLocationNumber] = useState(1);
  const [correctAnswers, setCorrectAnswers] = useState([]);
  const [incorrectAnswers, setIncorrectAnswers] = useState([]);
  const [token, setToken] = useState(null);

  const handleLogin = (email, token) => {
    setIsAuthenticated(true);
    setToken(token);
    localStorage.setItem('token', token);
    // Fetch user progress if neededs
  };

  const handleSignup = (email, token) => {
    setIsAuthenticated(true);
    setToken(token);
    localStorage.setItem('token', token);
    // Fetch user progress if needed
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    setToken(null);
    setLocationNumber(1);
    setCorrectAnswers([]);
    setIncorrectAnswers([]);
    localStorage.removeItem('token');
  };

  const fetchProgress = (token) => {
    axios
      .get(`${apiBaseUrl}api/progress`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const progress = response.data.progress;
        setLocationNumber(progress.locationNumber);
        setCorrectAnswers(progress.correctAnswers);
        setIncorrectAnswers(progress.incorrectAnswers);
      })
      .catch((error) => {
        console.error('Error fetching progress:', error);
      });
  };
  

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      setToken(storedToken);
      setIsAuthenticated(true);
      fetchProgress(storedToken);
    }
  }, []);

  return (
    <Router>
      {!isAuthenticated ? (
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login handleLogin={handleLogin} />} />
          <Route path="/signup" element={<Signup handleSignup={handleSignup} />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="/" element={<StartPage locationNumber={locationNumber} handleLogout={handleLogout} />} />
          <Route path="/verify" element={
            <Verify
              locationNumber={locationNumber}
              token={token}
            />
          } />
          <Route path="/hint" element={
            <Hint
              locationNumber={locationNumber}
              correctAnswers={correctAnswers}
              incorrectAnswers={incorrectAnswers}
              setCorrectAnswers={setCorrectAnswers}
              setIncorrectAnswers={setIncorrectAnswers}
              token={token}
            />
          } />
          <Route path="/answer" element={
            <Answer
              locationNumber={locationNumber}
              setLocationNumber={setLocationNumber}
              correctAnswers={correctAnswers}
              incorrectAnswers={incorrectAnswers}
              token={token}
            />
          } />
          <Route path="/final" element={
            <Final
              correctAnswers={correctAnswers}
              incorrectAnswers={incorrectAnswers}
              handleLogout={handleLogout}
            />
          } />
        </Routes>
      )}
    </Router>
  );
}

export default App;
