import React from 'react';
import { Link } from 'react-router-dom';
import backgroundImage from '../assets/logo.jpg'; // Import the image to use as text color

function Home() {
  const containerStyle = {
    backgroundColor: 'rgba(128, 128, 128, 0.5)', // Grey background with some opacity
    borderRadius: '15px', // Curved corners
    padding: '20px', // Padding around content
    textAlign: 'center', // Center-align text
    width: '300px', // Width of the block
    margin: '100px auto', // Center the block on the page
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 1)', // Soft shadow for better visibility
  };

  const buttonStyle = {
    padding: '10px 20px',
    margin: '10px',
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
    textDecoration: 'none',
    backgroundColor: '#333',
    color: '#fff',
  };

  const headingStyle = {
    fontSize: '36px',
    marginBottom: '20px',
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    WebkitBackgroundClip: 'text',
    color: 'transparent', // Make text transparent to show the clipped background
  };

  return (
    <div className="home-page">
      <div style={containerStyle}>
        <h1 style={headingStyle}>
          Faller Up
        </h1>
        <div className="page-content">
        <p style={{ color: '#fff', marginBottom: '50px' }}>
  Welcome to Faller.<br />
  Embark on an adventure full of surprises and discoveries. <br />Please login or sign up to begin.
</p>

          <button style={buttonStyle}>
            <Link to="/login" style={{ textDecoration: 'none', color: '#fff' }}>Login</Link>
          </button>
          <button style={buttonStyle}>
            <Link to="/signup" style={{ textDecoration: 'none', color: '#fff' }}>Sign up</Link>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Home;
